import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadAccountPlanConfigs =
  (): SmartrrThunkAction<
    "LOADING_ACCOUNT_PLAN_CONFIGS" | "ERROR_LOADING_ACCOUNT_PLAN_CONFIGS" | "LOADED_ACCOUNT_PLAN_CONFIGS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/account-plan/configs"),
      () =>
        dispatch({
          type: "LOADING_ACCOUNT_PLAN_CONFIGS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_ACCOUNT_PLAN_CONFIGS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      configs =>
        dispatch({
          type: "LOADED_ACCOUNT_PLAN_CONFIGS",
          payload: {
            configs,
          },
        })
    );

export const syncAccountPlans =
  (): SmartrrThunkAction<"SYNCING_ACCOUNT_PLANS" | "ERROR_SYNCING_ACCOUNT_PLANS" | "SYNCED_ACCOUNT_PLANS"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/account-plan/sync-plans"),
      () =>
        dispatch({
          type: "SYNCING_ACCOUNT_PLANS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_SYNCING_ACCOUNT_PLANS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      response =>
        dispatch({
          type: "SYNCED_ACCOUNT_PLANS",
          payload: {
            activePlan: response.activePlan,
            freeTrialPlan: response.freeTrialPlan,
            pendingPlan: response.pendingPlan,
          },
        })
    );

export const switchToAccountPlan =
  (
    planName: string,
    returnUrl: string,
    addToast: (content: string) => void,
    superUser?: boolean
  ): SmartrrThunkAction<
    "SWITCHING_TO_ACCOUNT_PLAN" | "ERROR_SWITCHING_TO_ACCOUNT_PLAN" | "SWITCHED_TO_ACCOUNT_PLAN"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/account-plan/switch-to", {
        reqBody: {
          planName,
          returnUrl,
          superUser,
        },
      }),
      () =>
        dispatch({
          type: "SWITCHING_TO_ACCOUNT_PLAN",
          payload: undefined,
        }),
      failure => {
        addToast("Failed to switch to account plan");
        return dispatch({
          type: "ERROR_SWITCHING_TO_ACCOUNT_PLAN",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      accountPlan => {
        return dispatch({
          type: "SWITCHED_TO_ACCOUNT_PLAN",
          payload: {
            accountPlan,
          },
        });
      }
    );
